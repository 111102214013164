<template>
  <div class="navMenu">
    <div class="navContent">
      <div class="topHeader">
        <div class="topMenu content_area">
          <div class="fr clearfix topSearch">
            <div class="fl">
              <el-autocomplete
                size="mini"
                v-model="searchVal"
                :fetch-suggestions="querySearchCompanyName"
                placeholder="请输入内容"
                :trigger-on-focus="false"
              >
                <i
                  slot="suffix"
                  class="el-icon-search cursor"
                  style="margin-right: 5px"
                  @click="searchContent"
                ></i
              ></el-autocomplete>
            </div>
            <div class="fl routerLink">
              <span>欢迎您！ </span>
              <span v-if="!IS_LOGIN">
                <router-link to="/sign" class="goLogin">
                  <span>登录</span> </router-link
                >｜
                <router-link to="/signUp" class="goLogin">
                  <span>注册</span>
                </router-link>
              </span>
              <span v-else>
                <el-popover
                  placement="bottom-start"
                  trigger="click"
                  v-model="visible"
                >
                  <div class="navMenuselfAccountInfo">
                    <p class="textOverflow">
                      姓名：{{
                        USER_INFO.name_en
                          | priorFormat(USER_INFO.name_zh, LOCALE)
                      }}
                    </p>
                    <p class="textOverflow">
                      职位：{{
                        USER_INFO.job_title_en
                          | priorFormat(USER_INFO.job_title_zh, LOCALE)
                      }}
                    </p>
                    <p class="textOverflow">
                      邮箱：{{ USER_INFO.email | textFormat }}
                    </p>
                    <p class="textOverflow">
                      手机号：{{ USER_INFO.mobile | textFormat }}
                    </p>
                  </div>

                  <div class="navMenuselfAccountInfoBtn">
                    <el-button
                      type="danger"
                      size="small"
                      @click="
                        visible = false;
                        logOut();
                      "
                      >退出登录</el-button
                    >
                  </div>
                  <img
                    :src="USER_INFO.avatar || defaultAvatar"
                    alt
                    class="userAvatar cursor"
                    slot="reference"
                  />
                </el-popover>
                <router-link to="/console/account/userInfo">控制台</router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="navMenuLink">
        <div class="content_area clearfix">
          <div class="fl pageLogo">
            <img :src="menuLogo" alt="" style="width:auto;height: 60px;" />
          </div>
          <div class="fr meau">
            <el-menu
              class="el-menu-atf"
              mode="horizontal"
              :default-active="activeIndex"
              menu-trigger="click"
              @select="selectMenu"
            >
              <el-menu-item index="/">网站首页</el-menu-item>
              <el-menu-item index="/survey">协会概况</el-menu-item>
              <el-submenu index="/2">
                <template slot="title">会员名录</template>
                <el-menu-item index="/companyCatalog">企业名录</el-menu-item>
                <el-menu-item index="/companyContactList"
                  >联系人列表</el-menu-item
                >
              </el-submenu>
              <el-menu-item index="/overseas">直通海外</el-menu-item>
              <el-menu-item index="">会议中心</el-menu-item>
              <el-submenu index="/talents">
                <template slot="title">人才专区</template>
                <el-menu-item index="/talents/recruitment"
                  >招聘信息</el-menu-item
                >
                <el-menu-item index="/talents/library">人才库</el-menu-item>
              </el-submenu>
              <el-menu-item index="/education_index">跨境电商</el-menu-item>
              <el-menu-item index="/legal_consult">法律咨询</el-menu-item>
              <el-menu-item index="/contactUs">联系我们</el-menu-item>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="300px"
      :close-on-click-modal="true"
    >
      <div class="tc openDialog">暂未开放</div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="isMumber"
      width="300px"
      :close-on-click-modal="true"
    >
      <div class="tc openDialogs">
        <span class="blue" @click="goToMember">成为会员</span>,解锁专属权益
      </div>
    </el-dialog>
  </div>
</template>

<script>
import searchCompany from "~xif/components/company/unit/searchCompany";

export default {
  components: { searchCompany },
  data() {
    return {
      isMumber: false,
      visible: false,
      defaultAvatar:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/headportrait.png",
      activeIndex: "/",
      menuLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/home/logo.png",
      searchVal: "",
      zhReg: /^[\u4e00-\u9fa5]+$/i,
      dialogVisible: false,
    };
  },
  methods: {
    logOut() {
      this.$store.commit("baseStore/CLEAR_USER_INFO");
      this.$router.push("/sign");
    },
    // 搜索框
    searchContent() {
      if (this.searchVal) {
        this.$router.push({
          path: "/companyCatalog",
          query: {
            parameter: this._encode({
              searchVal: this.searchVal,
            }),
          },
        });
      } else {
        this.$router.push("/companyCatalog");
      }
    },
    async querySearchCompanyName(queryString, cb) {
      let params = {};
      params.company_name = queryString;
      params.source = this.PJSource;
      params.usage = 2;
      params.is_vip = 1;
      let res = await this.$store.dispatch(
        "baseStore/company_associateSearch",
        params
      );
      let values;
      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    handleActive(path) {
      if (path.indexOf("survey") != -1) {
        this.activeIndex = path.substring(0, 7);
      } else {
        this.activeIndex = path;
      }
    },
    selectMenu(index, path) {
      if (index.indexOf("http") !== -1) {
        window.open(index);
        return;
      }
      if (index === "/talents/library") {
        if (!this.USER_INFO || !this.USER_INFO.aila_no) {
          this.isMumber = true;
          return;
        }
      }
      if (index == "") {
        this.dialogVisible = true;
        return;
      }
      this.$router.push(index);
    },
    goToMember() {
      this.isMumber = false;
      this.$router.push("/memberShip");
    },
  },
  watch: {
    $route(nv) {
      this.handleActive(nv.path);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
a{
  color: #fff;
}
.navMenu {
  .userAvatar {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15px;
  }
  height: 100px;
  width: 100%;
  position: relative;

  .navContent {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0px;
    left: 0px;
    .content_area {
      position: relative;
      .logo {
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
    .topSearch {
      line-height: 40px;
      .routerLink {
        margin-left: 32px;
        color:#fff;
      }
      .iconSearch {
        margin-right: 5px;
      }
    }
  }
  .topHeader {
    height: 40px;
    background: #0D6BB9;
    overflow: hidden;
  }
  .navMenuLink {
    height: 60px;
    background: #fff;
  }
}
.userImg {
  margin-right: 10px;
  img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
}
.goLogin {
  color: #fff;
}
/deep/.el-input--mini .el-input__inner {
  border-radius: 15px;
}
.openDialog {
  height: 100px;
  line-height: 100px;
}
/**
导航条的样式
*/
.el-menu-atf {
  border-bottom: none;
}
.el-menu {
  background: transparent;
}
.submenu {
  width: 110px;
}
/deep/ .el-submenu__icon-arrow{
  color: #909399!important;
}
</style>
<style lang="less">
.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 110px !important;
  text-align: center;
}
.navMenuselfAccountInfo {
  p {
    line-height: 28px;
  }
  .navMenuselfAccountInfoBtn {
    margin-top: 8px;
  }
}
.blue {
  color: #409eff;
  cursor: pointer;
}
.openDialogs {
  height: 100px;
  line-height: 100px;
}
</style>
