export const baseConsoleRoutes = [
  {
    path: "/console/account/userInfo", // 个人信息
    name: "account-userInfo",
    component: (resolve) => require(["~bac/views/account/userInfo"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "1",
      nameId: "1-1",
      auth: "mbc:usi",
      platform: ["ALL"],
    },
  },
  {
    path: "/console/account/security", //安全设置
    name: "account-security",
    component: (resolve) => require(["~bac/views/account/security"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "1",
      nameId: "1-2",
      auth: "mbc:ses",
      platform: ["ALL"],
    },
  },
  {
    path: "/console/account/realName", //实名认证
    name: "account-realName",
    component: (resolve) => require(["~bac/views/account/realName"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "1",
      nameId: "1-3",
      auth: "",
      hiddenInEnLocale: true,
      platform: ["EXP"],
    },
  },
  {
    path: "/console/company/basic", //企业信息
    name: "company-basic",
    component: (resolve) => require(["~bac/views/company/basic"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-1",
      auth: "cos:coi",
      platform: ["ALL"],
    },
  },
  {
    path: "/console/company/picture", //企业图片
    name: "company-picture",
    component: (resolve) => require(["~bac/views/company/picture"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-2",
      auth: "coi:img",
      notShow: ["OSM","NBK"],
      platform: ["ALL"],
    },
  },
  {
    path: "/console/company/certificateList", //企业证书
    name: "company-certificateList",
    component: (resolve) =>
      require(["~bac/views/company/certificateList"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-3",
      auth: "coi:asc",
      notShow: ["OSM","NBK"],
      platform: ["ALL"],
    },
  },

  {
    path: "/console/company/video", //企业视频
    name: "company-video",
    component: (resolve) => require(["~bac/views/company/video"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-4",
      auth: "coi:ved",
      notShow: ["OSM","NBK"],
      platform: ["ALL"],
    },
  },
  {
    path: "/console/company/accountRole", //账号管理
    name: "company-accountRole",
    component: (resolve) =>
      require(["~bac/views/company/accountRole"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-5",
      auth: "coi:acm",
      platform: ["TRF", "SIF", "LYS", "SCB", "TIF", "QLC", "GBK", "HBS", "MPA", "XIF","DLS","HPH","DGB","HNS","JZS","HYM"],
    },
  },
  {
    path: "/console/company/YMPaccountRole", //账号管理
    name: "company-YMPaccountRole",
    component: (resolve) =>
      require(["~bac/views/company/YMPaccountRole"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-10",
      auth: "coi:acm",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/company/business", //企业业务
    name: "company-business",
    component: (resolve) => require(["~bac/views/company/business"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-6",
      auth: "coi:cbs",
      notShow: ["OSM", "HBS","DGB","NBK"],
      platform: ["ALL"],
    },
  },
  {
    path: "/console/company/attention", //关注点赞
    name: "company-attention",
    component: (resolve) => require(["~bac/views/company/attention"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-7",
      auth: "",
      platform: ["COP"],
    },
  },
  {
    path: "/console/company/favorite", //收藏夹
    name: "company-favorite",
    component: (resolve) => require(["~bac/views/company/favorite"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-8",
      auth: "",
      platform: ["TRF", "SIF", "LYS", "SCB", "TIF", "QLC", "MPA", "GBK", "XIF","DLS","HPH","HNS","JZS","HYM"],
    },
  },
  {
    path: "/console/company/product", //产品信息
    name: "company-product",
    component: (resolve) => require(["~bac/views/company/product"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-11",
      auth: "cos:prd",
      platform: ["GBK"],
    },
  },
  // 会议管理
  // {
  //     path: '/console/conference/myConference',                //我的会议
  //     name: 'conference-myConference',
  //     component: resolve => require(['~bac/views/conference/myConference'], resolve),
  //     meta: {
  //         layout: 'baseConsole',
  //         parentId: '3',
  //         nameId: '3-1',
  //         auth: '',
  //         platform: ['COP']
  //     }
  // },
  {
    path: "/console/conference/myCatalogue", // 我的会刊
    name: "myCatalogue",
    component: (resolve) => require(["~bac/views/myCatalogue/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-20",
      auth: "mem:mmc",
      platform: ["AXP"],//,"BFM"
    },
  },
  {
    path: "/console/conference/caseCard", //名片夹
    name: "conference-caseCard",
    component: (resolve) =>
      require(["~bac/views/conference/caseCard"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-3",
      //auth: 'mem:cad',
      platform: ["EXP", "FDA", "OSM", "SIF", "LYS", "AXP", "TRF", "QLC", "MPA", "XIF","DLS","HPH","HNS","JZS","NBK","BFM","HYM"],
    },
  },
  {
    path: "/console/conference/caseCard/admin", //名片管理
    name: "conference-caseCard",
    component: (resolve) =>
      require(["~bac/views/conference/caseCard/admin"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-4",
      //auth: 'mem:cai',
      notUseMain: true,
      platform: ["EXP", "FDA", "OSM", "SIF", "LYS", "AXP", "TRF", "QLC", "MPA", "XIF","DLS","HPH","HNS","JZS","NBK","BFM","HYM"],
    },
  },
  {
    path: "/console/conference/attendance", //参会人员管理
    name: "conference-attendance",
    component: (resolve) =>
      require(["~bac/views/conference/attendance/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-5",
      auth: "mem:jmp",
      platform: ["EXP", "FDA", "OSM", "AXP","NBK","BFM"],
    },
  },
  {
    path: "/console/conference/live/guest", //嘉宾/主持人设置
    name: "conference-guest",
    component: (resolve) =>
      require(["~bac/views/conference/live/guest"], resolve),
    meta: {
      layout: "baseConsole",
      hiddenInMenu: true,
      notUseMain: true,
      parentId: "3",
      nameId: "3-6",
      auth: "mem:jmp",
      platform: ["EXP", "FDA", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/live/index", //直播管理列表
    name: "conference-guest",
    component: (resolve) =>
      require(["~bac/views/conference/live/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-7",
      auth: "mem:lim",
      platform: ["EXP", "FDA", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/live/live_set", //直播  创建&修改
    name: "conference-live_set",
    component: (resolve) =>
      require(["~bac/views/conference/live/live_set"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-8",
      auth: "mem:lim:add",
      platform: ["EXP", "FDA", "AXP","BFM"],
      hiddenInMenu: true,
      notUseMain: true,
    },
  },
  {
    path: "/console/conference/boothManage", //展台管理
    name: "conference-mySponsor",
    component: (resolve) =>
      require(["~bac/views/conference/boothManage"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-2",
      auth: "mem:bom",
      notUseMain: true,
      platform: ["EXP", "FDA", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/boothPreview", //展台预览
    name: "conference-boothPreview",
    component: (resolve) =>
      require(["~bac/views/conference/boothManage/preview"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-10",
      notUseMain: true,
      hiddenInMenu: true,
      platform: ["EXP", "FDA", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/redEnvelopeList", //业务红包管理
    name: "conference-redEnvelopeAdmin",
    component: (resolve) =>
      require(["~bac/views/conference/redEnvelopeAdmin/list"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-11",
      auth: "mem:yrm",
      notUseMain: true,
      platform: ["EXP", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/receiveBusiness", //业务红包领取
    name: "conference-receiveBusiness",
    component: (resolve) =>
      require([
        "~bac/views/conference/redEnvelopeAdmin/receiveBusiness",
      ], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-17",
      auth: "mem:yrm",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["EXP", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/cash", //现金红包管理
    name: "conference-cash",
    component: (resolve) =>
      require(["~bac/views/conference/redEnvelopeAdmin/cash"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-12",
      auth: "mem:xrm",
      notUseMain: true,
      platform: ["EXP", "AXP","BFM"],
    },
  },

  {
    path: "/console/conference/cash_detail", //现金红包 领取记录
    name: "conference-cash_detail",
    component: (resolve) =>
      require(["~bac/views/conference/redEnvelopeAdmin/cash_detail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-18",
      auth: "",
      notUseMain: true,
      hiddenInMenu: true,
      platform: ["EXP", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/myRedEnvelope", //我的红包
    name: "conference-myRedEnvelope",
    component: (resolve) =>
      require([
        "~bac/views/conference/redEnvelopeAdmin/myRedEnvelope",
      ], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-13",
      auth: "mem:mrb",
      platform: ["EXP"],
    },
  },
  {
    path: "/console/conference/myUsedList", //我的红包-业务红包使用记录
    name: "conference-myUsedList",
    component: (resolve) =>
      require(["~bac/views/conference/redEnvelopeAdmin/myUsedList"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-13",
      hiddenInMenu: true,
      auth: "",
      platform: ["EXP", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/accountRecords", // 账户记录
    name: "conference-accountRecords",
    component: (resolve) =>
      require([
        "~bac/views/conference/redEnvelopeAdmin/accountRecords",
      ], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-14",
      auth: "mem:acr",
      platform: ["EXP", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/myReserved", //我的洽谈
    name: "conference-myReserved",
    component: (resolve) =>
      require(["~bac/views/conference/myReserved"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-9",
      auth: "mem:myi",
      platform: ["EXP", "FDA", "SIF", "LYS", "OSM", "GBK", "AXP", "TRF", "XIF","DLS","NBK"],//,"BFM"
    },
  },
  {
    path: "/console/conference/record", //洽谈记录
    name: "conference-record",
    component: (resolve) =>
      require(["~bac/views/conference/record/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      hiddenInMenu: true,
      nameId: "3-16",
      auth: "",
      platform: ["EXP", "FDA", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/myCollection", //我的收藏
    name: "conference-myCollection",
    component: (resolve) =>
      require(["~bac/views/conference/myCollection"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-15",
      // auth: 'mem:xrm',
      // notUseMain: true,
      platform: ["EXP", "FDA", "AXP","BFM"],
    },
  },
  {
    path: "/console/conference/negotiationSetting", //洽谈设置
    name: "conference-negotiationSetting",
    component: (resolve) =>
      require(["~bac/views/conference/negotiationSettings"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "3",
      nameId: "3-19",
      // auth: 'mem:xrm',
      // notUseMain: true,
      platform: ["OSM","NBK"],
    },
  },
  {
    path: "/console/talentCenter/positionAdmin", // 人才中心-职位管理
    name: "talentCenter-positionAdmin",
    component: (resolve) =>
      require(["~bac/views/talentCenter/positionAdmin/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "4",
      nameId: "4-1",
      auth: "tac:jom", //
      platform: ["TRF", "SIF", "LYS", "SCB", "QLC", "MPA", "GBK", "HBS", "XIF","DLS","TIF","HPH","DGB","HNS","JZS","HYM"],
    },
  },
  {
    path: "/console/talentCenter/positionResume", // 人才中心-简历管理
    name: "talentCenter-positionResume",
    component: (resolve) =>
      require(["~bac/views/talentCenter/positionResume/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "4",
      nameId: "4-2",
      auth: "tac:rem", //
      platform: ["TRF", "SIF", "LYS", "SCB", "MPA", "GBK", "HBS", "QLC", "XIF","DLS","TIF","HPH","DGB","HNS","JZS","HYM"],  // "TIF",
    },
  },
  {
    path: "/console/talentCenter/interview", // 人才中心-面试管理
    name: "talentCenter-interview",
    component: (resolve) =>
      require(["~bac/views/talentCenter/interview/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "4",
      nameId: "4-3",
      auth: "tac:ivm",
      platform: ["TRF", "SIF", "LYS", "SCB", "MPA", "HBS", "QLC", "XIF","DLS","TIF","HPH","DGB","HNS","JZS","HYM"], // "TIF",
    },
  },
  {
    path: "/console/myApply/membershipApply", // 我的申请-入会申请
    name: "talentCenter-membershipApply",
    component: (resolve) =>
      require(["~bac/views/myApply/membershipApply/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "5",
      nameId: "5-1",
      // auth: "mal:jma",
      platform: ["SIF", "TRF", "LYS", "SCB", "TIF", "QLC", "MPA", "GBK", "XIF","DLS","HPH","HNS","JZS","HYM"],
    },
  },
  // {
  //     path: '/console/myApply/membershipApply/membershipApply',                 // 我的申请-入会申请（适用于物贸联合会）
  //     name: 'talentCenter-membershipApply_2',
  //     component: resolve => require(['~bac/views/myApply/membershipApply/membershipApply'], resolve),
  //     meta: {
  //         layout: 'baseConsole',
  //         parentId: '5',
  //         nameId: '5-3',
  //         auth: '',
  //         platform: ['TRF'],
  //     }
  // },
  {
    path: "/console/myApply/activityApply", // 我的申请-活动申请
    name: "talentCenter-activityApply",
    component: (resolve) =>
      require(["~bac/views/myApply/activityApply/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "5",
      nameId: "5-2",
      // auth: 'mal:aca',
      platform: ["TRF", "SIF", "LYS", "SCB", "TIF", "QLC", "MPA", "GBK", "AXP", "XIF","DLS","BFM","HYM"],
    },
  },
  {
    path: "/console/conference/myReservedSoc", //我的预约
    name: "conference-myReserved-1v1",
    component: (resolve) =>
      require(["~bac/views/conference/myReserved"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "6",
      nameId: "6-1",
      auth: "",
      platform: ["SIF", "LYS", "QLC", "TRF", "MPA", "XIF","DLS","HPH", "HNS","JZS","HYM"],
    },
  },
  {
    path: "/console/myshippingOrder", //玉蟒  我的委托列表 => 改为海运订单列表
    name: "myEntrustList",
    component: (resolve) =>
      require(["~bac/views/dealManagement/shippingOrder/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-2",
      auth: "cat:mor",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myInquire/list", //玉蟒  我询价
    name: "myInquireList",
    component: (resolve) => require(["~bac/views/myInquire/list"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-7",
      auth: "cat:men",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myInquire/detail", //玉蟒  我询价
    name: "myInquireDetail",
    component: (resolve) => require(["~bac/views/myInquire/detail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-8",
      auth: "",
      hiddenInMenu: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myoffer/list", //玉蟒  我的报价
    name: "myofferList",
    component: (resolve) => require(["~bac/views/myOffer/list"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-9",
      auth: "sale:idx",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myoffer/detail", //玉蟒  我的报价
    name: "myofferDetail",
    component: (resolve) => require(["~bac/views/myOffer/detail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-10",
      auth: "",
      hiddenInMenu: true,
      platform: ["YMP"],
    },
  },
  // {
  //   path: "/console/myoffer/share", //玉蟒  我的报价--分享
  //   name: "myofferShare",
  //   component: (resolve) => require(["~bac/views/myOffer/share"], resolve),
  //   meta: {
  //     layout: "baseConsole",
  //     parentId: "7",
  //     nameId: "7-11",
  //     auth: "",
  //     hiddenInMenu: true,
  //     platform: ["YMP"],
  //   },
  // },
  {
    path: "/console/myoffer/copy", //玉蟒  我的报价--复制
    name: "myofferCopy",
    component: (resolve) => require(["~bac/views/myOffer/copy"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-12",
      auth: "",
      hiddenInMenu: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/customer/list", //玉蟒  客户询价--列表
    name: "customerList",
    component: (resolve) => require(["~bac/views/customer/list"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-13",
      auth: "sale:inq",
      hiddenInMenu: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/customer/detail", //玉蟒  客户询价-详情
    name: "customerDetail",
    component: (resolve) => require(["~bac/views/customer/detail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-14",
      auth: "",
      hiddenInMenu: true,
      platform: ["YMP"],
    },
  },
  // {
  //   path: "/console/myEntrust/details", //玉蟒  我的委托详情
  //   name: "myEntrustDetails",
  //   component: (resolve) =>
  //     require(["~bac/views/dealManagement/myEntrust/details"], resolve),
  //   meta: {
  //     layout: "baseConsole",
  //     parentId: "7",
  //     nameId: "7-3",
  //     auth: "",
  //     hiddenInMenu: true,

  //     platform: ["YMP"],
  //   },
  // },
  {
    path: "/console/myEntrust/detailsOrdered", //玉蟒  我的委托详情（正操作）
    name: "detailsOrdered",
    component: (resolve) =>
      require(["~bac/views/dealManagement/myEntrust/detailsOrdered"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-6",
      auth: "",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myOffer/shareRecords", //玉蟒  分享记录
    name: "shareRecords",
    component: (resolve) =>
      require(["~bac/views/myOffer/shareRecords"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-18",
      auth: "sale:records",
      // hiddenInMenu: false,
      // notUseMain: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myOffer/shareRecords_details", //玉蟒  分享记录
    name: "shareRecords_details",
    component: (resolve) =>
      require(["~bac/views/myOffer/shareRecords_details"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-19",
      auth: "",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myBill/index", //玉蟒  我的账单
    name: "myBill",
    component: (resolve) =>
      require(["~bac/views/myBill/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-20",
      auth: "sale:bill",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myBill/detail", //玉蟒  我的账单详情
    name: "myBillDetail",
    component: (resolve) =>
      require(["~bac/views/myBill/detail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-21",
      auth: "",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/clients", //玉蟒  客户管理
    name: "clients",
    component: (resolve) =>
      require(["~bac/views/customerManage/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "13",
      nameId: "13-1",
      auth: "ctr:clients",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/clients/booking", //玉蟒  客户订舱
    name: "customerBooking",
    component: (resolve) =>
      require(["~bac/views/customerManage/customerBooking"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "13",
      nameId: "13-2",
      auth: "",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/clients/companyInfo", //玉蟒
    name: "companyInfo",
    component: (resolve) =>
      require(["~bac/views/customerManage/companyInfo"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "13",
      nameId: "13-3",
      auth: "",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["YMP"],
    },
  },
  // {
  //   path: "/console/myEntrust/fclOrderDetail", //玉蟒  我的委托详情 (整箱)
  //   name: "fclOrderDetail",
  //   component: (resolve) =>
  //     require(["~bac/views/dealManagement/shippingOrder/index"], resolve),
  //   meta: {
  //     layout: "baseConsole",
  //     parentId: "7",
  //     nameId: "7-17",
  //     auth: "",
  //     hiddenInMenu: true,
  //     notUseMain: true,
  //     platform: ["YMP"],
  //   },
  // },
  {
    path: "/console/myoffer/customer_inquiry", //玉蟒  销售端--客户询价
    name: "customer_inquiry",
    component: (resolve) =>
      require(["~bac/views/myOffer/customer_inquiry"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-15",
      auth: "sale:inq",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/myoffer/customer_inquiry_detail", //玉蟒  销售端--客户询价--详情
    name: "customer_inquiry_detail",
    component: (resolve) =>
      require(["~bac/views/myOffer/customer_inquiry_detail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-16",
      hiddenInMenu: true,
      auth: "",
      platform: ["YMP"],
    },
  },
  {
    path: "/console/shippingOrder", //玉漭销售  海运订单
    name: "myEntrustDetails",
    component: (resolve) =>
    require(["~bac/views/dealManagement/shippingOrder/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-22",
      auth: "sale:mor",
      hiddenInMenu: false,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/consulting", //玉漭销售  舱位咨询
    name: "consulting",
    component: (resolve) =>
    require(["~bac/views/dealManagement/consulting/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-23",
      auth: "sale.csg",
      hiddenInMenu: false,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/consulting/detail", //玉蟒  舱位咨询详情
    name: "consultingDetail",
    component: (resolve) =>
      require(["~bac/views/dealManagement/consulting/detail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-24",
      auth: "",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/consulting/manydetail", //玉蟒  舱位咨询详情
    name: "consultingDetail",
    component: (resolve) =>
      require(["~bac/views/dealManagement/consulting/manydetail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "7",
      nameId: "7-24",
      auth: "",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["YMP"],
    },
  },
  // {
  //   path: "/console/dataBank", //玉漭  资料库
  //   name: "myEntrustDetails",
  //   component: (resolve) =>
  //     require(["~bac/views/dealManagement/dataBank/index"], resolve),
  //   meta: {
  //     layout: "baseConsole",
  //     parentId: "7",
  //     nameId: "7-4",
  //     auth: "cat:dat",

  //     hiddenInMenu: false,
  //     platform: ["YMP"],
  //   },
  // },
  // {
  //   path: "/console/adress", //玉漭  地址管理
  //   name: "myEntrustDetails",
  //   component: (resolve) =>
  //     require(["~bac/views/dealManagement/adress/index"], resolve),
  //   meta: {
  //     layout: "baseConsole",
  //     parentId: "7",
  //     nameId: "7-5",
  //     auth: "cat:ads",
  //     hiddenInMenu: false,
  //     platform: ["YMP"],
  //   },
  // },
  {
    path: "/console/company/invoice",
    name: "company-invoice",
    component: (resolve) => require(["~bac/views/company/invoice"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "2",
      nameId: "2-9",
      auth: "cos:mak",
      hiddenInMenu: false,
      platform: ["YMP"],
    },
  },
  {
    path: "/console/directOverseas/myInquiry", //我的发盘
    name: "directOverseas_myInquiry",
    component: (resolve) =>
      require(["~bac/views/directOverseas/myInquiry"], resolve),
    meta: {
      layout: "baseConsole",
      notUseMain: true,
      auth: "dos:mil",
      parentId: "8",
      nameId: "8-1",
      platform: ["SIF", "TRF", "LYS", "GBK", "QLC", "MPA", "XIF", "DLS", "HPH", "HNS", "JZS", "TIF","HYM"],
    },
  },
  {
    path: "/console/directOverseas/inquiryDetail", //我的发盘--详情
    name: "directOverseas_inquiryDetail",
    component: (resolve) =>
      require(["~bac/views/directOverseas/inquiryDetail"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "8",
      nameId: "8-2",
      auth: "dos:mil",
      hiddenInMenu: true,
      notUseMain: true,
      platform: ["SIF", "TRF", "LYS", "GBK", "QLC", "MPA", "XIF","DLS","HPH", "HNS","JZS","HYM"],
    },
  },
  {
    path: "/console/talent/resume", //人才专区-我的简历
    name: "resume",
    component: (resolve) => require(["~bac/views/talent/resume"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "9",
      nameId: "9-1",
      platform: ["SCB", "TRF", "SIF", "HBS", "QLC", "LYS", "MPA", "XIF","DLS","TIF","HPH","DGB","HNS","JZS"],
    },
  },
  {
    path: "/console/talent/jobSearch", //人才专区-我的求职
    name: "jobSearch",
    component: (resolve) => require(["~bac/views/talent/jobSearch"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "9",
      nameId: "9-2",
      platform: ["SCB", "SIF", "HBS", "QLC", "LYS", "MPA", "XIF","DLS","TIF","HPH","DGB","HNS","JZS"],
    },
  },
    {
    path: "/console/couse/list", //公司订单
    name: "companyOrder",
    component: (resolve) => require(["~bac/views/course/list"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "10",
      nameId: "10-1",
      auth: "",
      platform: ["SCB"],
    }
  },
  // {
  //   path: "/console/order/companyOrder", //公司订单
  //   name: "companyOrder",
  //   component: (resolve) => require(["~bac/views/order/companyOrder"], resolve),
  //   meta: {
  //     layout: "baseConsole",
  //     parentId: "10",
  //     nameId: "10-1",
  //     auth: "",
  //     platform: ["SCB", "TIF"],
  //   }
  // },
  {
    path: "/console/order/myOrder", //我的订单
    name: "myOrder",
    component: (resolve) => require(["~bac/views/order/myOrder"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "11",
      nameId: "11-2",
      auth: "",
      platform: ["SCB"],
    },
  },
  {
    path: "/console/pre/preClassification", //预归类管理
    name: "preClassification",
    component: (resolve) => require(["~bac/views/pre/index"], resolve),
    meta: {
      layout: "baseConsole",
      parentId: "12",
      nameId: "12-1",
      auth: "pre:man",
      platform: ["SCB"],
    },
  },

];
