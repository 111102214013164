export const creditClass = [           //信用等级
    {
        value: 1,
        label_zh: 'AAA',
        label_en: ''
    },
    {
        value: 2,
        label_zh: 'AA',
        label_en: ''
    },
    {
        value: 3,
        label_zh: 'A',
        label_en: ''
    }
]

export const unitType = [           //单位类型
    {
        value: 5,
        label_zh: '会长单位',
        label_en: ''
    },
    {
        value:4,
        label_zh:"副会长单位",
        label_en:''
    },
    {
        value: 3,
        label_zh: '监事单位',
        label_en: ''
    },
    {
        value: 2,
        label_zh: '理事单位',
        label_en: ''
    },
    {
        value: 1,
        label_zh: '会员单位',
        label_en: ''
    }
]
export const cityList = [
    { id: 1, country_id: 1, name_zh: "香港", name_en: "Hong Kong" },
    { id: 2, country_id: 1, name_zh: "澳门", name_en: "Macao" },
    { id: 3, country_id: 1, name_zh: "北京", name_en: "Beijing" },
    { id: 4, country_id: 1, name_zh: "天津", name_en: "Tianjin" },
    { id: 5, country_id: 1, name_zh: "石家庄", name_en: "Shijiazhuang" },
    { id: 6, country_id: 1, name_zh: "唐山", name_en: "Tangshan" },
    { id: 7, country_id: 1, name_zh: "秦皇岛", name_en: "Qinhuangdao" },
    { id: 8, country_id: 1, name_zh: "邯郸", name_en: "Handan" },
]
export const yearList = [           //单位类型
    {
        value: 1,
        label_zh: '2020年',
        label_en: ''
    },
    {
        value: 2,
        label_zh: '2019年',
        label_en: ''
    },
    {
        value: 3,
        label_zh: '2018年',
        label_en: ''
    },
    {
        value: 4,
        label_zh: '2017年',
        label_en: ''
    },
    {
        value: 5,
        label_zh: '2016年',
        label_en: ''
    },
    {
        value: 6,
        label_zh: '2015年',
        label_en: ''
    },
    {
        value: 7,
        label_zh: '2014年',
        label_en: ''
    }
]
export const staffsizeList = [ //企业规模
    {
        value: 1,
        label_en: "1-50 people",
        label_zh: "1-50人"
    },
    {
        value: 2,
        label_en: "51-100 people",
        label_zh: "51-100人"
    },
    {
        value: 3,
        label_en: "101-200 people",
        label_zh: "101-200人"
    },
    {
        value: 4,
        label_en: "201-500 people",
        label_zh: "201-500人"
    },
    {
        value: 5,
        label_en: "501-1000 people",
        label_zh: "501-1000人"
    },
    {
        value: 6,
        label_en: "More than 1001 people",
        label_zh: "1001人及以上"
    }
]
export const weeksList = [ //企业规模
    {
        value: 1,
        label: "周一",
    },
    {
        value: 2,
        label: "周二",
    },
    {
        value: 3,
        label: "周三",
    },
    {
        value: 4,
        label: "周四",
    },
    {
        value: 5,
        label: "周五",
    },
    {
        value: 6,
        label: "周六",
    },
    {
        value: 0,
        label: "周日",
    }
  ]
  

export const positionTypeList = [           //职位类型
  {
    value: 1,
    label_zh: '销售'
  },
  {
    value: 2,
    label_zh: '操作/报关/单证'
  },
  {
    value: 3,
    label_zh: '客服'
  },
  {
    value: 4,
    label_zh: '市场/商务'
  },
  {
    value: 5,
    label_zh: '仓储/运输/配送'
  },
  {
    value: 6,
    label_zh: '财务'
  },
  {
    value: 7,
    label_zh: '人力资源'
  },
  {
    value: 8,
    label_zh: '行政/后勤/文秘'
  },
  {
    value: 9,
    label_zh: '采购/外贸'
  },
  {
    value: 10,
    label_zh: '高级管理'
  },
  {
    value: 11,
    label_zh: '其他'
  }
]