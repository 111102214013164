import Vue from 'vue'
import { baseConsoleRoutes } from '~bac/router'
Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [{
        path: '/test',                                              //首页
        name: 'test',
        component: resolve => require(['~xif/views/test'], resolve),
    },
    {
        path: '/',                                              //首页
        name: 'index',
        component: resolve => require(['~xif/views/index'], resolve),

    },
    {
        path: '/baseShippingDate',                                              //查询结果交互
        name: 'index',
        component: resolve => require(['@/baseComponents/baseShippingDate/index'], resolve),
    },
    {
        path: '/downloadSection',                                              //首页
        name: 'downloadSection',
        component: resolve => require(['~xif/views/index/downloadSection/index'], resolve),
    },
    {
        path: '/education_index',                                              //跨境电商
        name: 'education_index',
        component: resolve => require(['~xif/views/education/index'], resolve),
    },
    {
        path: "/legal_consult",                                                // 法律咨询
        name: "legal_consult",
        component: resolve => require(['~xif/views/legalConsult/index'], resolve)
    },
    {
        path: '/lawInfoList',
        name: "lawInfoList",
        component: resolve => require(['~xif/views/legalConsult/crossList'],resolve)
    },
    {
        path: "/crossList",
        name: "crossList",
        component: resolve => require(['~xif/views/education/crossList'], resolve)
    },
    {
        path: '/talents/recruitment',//招聘信息
        name: 'recruitment',
        component: resolve => require(['~xif/views/talents/recruitment'], resolve)
    },
    {
        path: '/talents/library',//人才库
        name: 'library',
        component: resolve => require(['~xif/views/talents/library'], resolve)
    },
    {
        path: '/positionDetail', //职位详情
        name: 'positionDetail',
        component: resolve => require(['~xif/views/position/detail'], resolve)
    },
    {
        path: '/education_detail',                                              //教育培训--详情
        name: 'education_detail',
        component: resolve => require(['~xif/views/education/detail'], resolve),
    },
    {
        path: '/activitys',                                              //首页--活动
        name: 'activitys',
        component: resolve => require(['~xif/views/index/activitys/index'], resolve),
        children: []
    },
    {
        path: '/activity',                                  //培训活动
        name: 'activity',
        component: resolve => require(['~xif/views/activity/index'], resolve)
    },
    {
        path: '/memberShip',                                              //申请入会
        name: 'memberShip',
        component: resolve => require(['~xif/views/index/memberShip/index'], resolve),
        children: [
            {
                path: '/',
                name: 'applyFor',
                component: resolve => require(['~xif/views/index/memberShip/flow'], resolve)
            }, {
                path: '/memberShip/applyFor',
                name: 'applyFor',
                component: resolve => require(['~xif/views/index/memberShip/applyFor'], resolve)
            },
        ]
    },
    {
        path: '/survey',                                              //协会概况
        name: 'survey',
        component: resolve => require(['~xif/views/survey'], resolve),
        children: [{
            path: '/',
            name: 'brief',
            component: resolve => require(['~xif/views/survey/brief'], resolve)
        }, {
            path: '/survey/director',
            name: 'director',
            component: resolve => require(['~xif/views/survey/director'], resolve)
        }, {
            path: '/survey/framework',
            name: 'framework',
            component: resolve => require(['~xif/views/survey/framework'], resolve)
        }, {
            path: '/survey/leader',
            name: 'leader',
            component: resolve => require(['~xif/views/survey/leader'], resolve)
        }, {
            path: '/survey/supervisor',
            name: 'supervisor',
            component: resolve => require(['~xif/views/survey/supervisor'], resolve)
        }, {
            path: '/survey/member',
            name: 'member',
            component: resolve => require(['~xif/views/survey/member'], resolve)
        }, {
            path: '/survey/constitution',
            name: 'constitution',
            component: resolve => require(['~xif/views/survey/constitution'], resolve)
        }, {
            path: "/survey/memorabilia",
            name: 'memorabilia',
            component: resolve => require(['~xif/views/survey/memorabilia'], resolve)
        }]
    }, {
        path: '/companyCatalog',//会员名录
        name: 'companyCatalog',
        component: resolve => require(['~xif/views/company/companyCatalog'], resolve)
    }, {
        path: '/whereDelivery',//运去哪
        name: 'whereDelivery',
        component: resolve => require(['~xif/views/whereDelivery/index'], resolve)
    }, {
        path: '/companyContactList',
        name: 'companyContactList',
        component: resolve => require(['~xif/views/company/companyContactList'], resolve)
    }, {
        path: '/companyDetail',
        name: 'companyDetail',
        component: resolve => require(['~xif/views/company/companyDetail'], resolve)
    }, {
        path: '/sign',
        name: 'sign',
        component: resolve => require(['~xif/views/users/sign'], resolve),
        meta: {
            layout: 'sign'
        }
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        component: resolve => require(['~xif/views/users/forgetPassword'], resolve),
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: resolve => require(['~xif/views/users/resetPassword'], resolve),
    },
    {
        path: '/signUp',
        name: 'signUp',
        component: resolve => require(['~xif/views/users/signUp'], resolve),
        meta: {
            layout: 'sign'
        }
    }, {
        path: '/activeAccount',
        name: 'activeAccount',
        component: resolve => require(['~xif/views/users/activeAccount'], resolve),
    }, {
        path: '/memberCenter',
        name: 'memberCenter',
        component: resolve => require(['~xif/views/memberCenter'], resolve)
    },
    {
        path: "/creditAssessment",//信用评估页面
        name: "creditAssessment",
        component: resolve => require(['~xif/views/creditAssessment'], resolve)
    },
    {
        path: "/creditDetail",//信用评估详情页面
        name: "creditDetail",
        component: resolve => require(['~xif/views/creditAssessment/detail'], resolve)
    },
    {
        path: "/basicInfo",//完善信息
        name: "basicInfo",
        component: resolve => require(['~xif/views/users/basicInfo'], resolve)
    },
    {
        path: "/contactUs",//完善信息
        name: "contactUs",
        component: resolve => require(['~xif/views/contactUs/index'], resolve)
    },
    {
        path: "/detail",//完善信息
        name: "detail",
        component: resolve => require(['~xif/views/index/detail'], resolve)
    },
    {
        path: "/activityDetail",//首页活动详情
        name: "activityDetail",
        component: resolve => require(['~xif/views/index/activityDetail'], resolve)
    },
    {
        path: "/memberInfo",
        name: "memberInfo",
        component: resolve => require(['~xif/views/index/detail/companyInfo'], resolve)
    },
    {
        path: '/handleInitialParams',
        name: 'handleInitialParams',
        meta: {
            layout: 'empty'
        },
        component: resolve => require(['~xif/views/users/initPage'], resolve)
    },
    {
        path: "/overseas",//直通海外
        name: "overseas",
        component: resolve => require(['@/baseComponents/baseInquiry/overseas/index'], resolve)
    },
    {
        path: "/publishInquiry",//一键发盘
        name: "publishInquiry",
        component: resolve => require(['@/baseComponents/baseInquiry/publishInquiry/index'], resolve)
    }
    ].concat(baseConsoleRoutes).concat([{
        path: "**",
        redirect: "/"
    }]),
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})
