<template>
  <div class="bottom_content">
    <div class="site">
      
    </div>
  </div>
</template>

<script>
export default {
  name: "commonBottomContent",
  serverCacheKey: () => "commonBottomContentV0.0.0",
};
</script>

<style lang="less" scoped>
.bottom_content {
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  .gonganImg {
    width: 14px;
    height: 14px;
    margin-top: -5px;
  }
  p {
    &.noMargin {
      margin-top: 8px !important;
    }
    color: #666;
    a {
      color: #666;
    }
  }
}
</style>
