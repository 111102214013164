<template>
  <div class="search">
    <el-autocomplete
      class="inline-input"
      v-model="name"
      :fetch-suggestions="querySearchCompanyName"
      :placeholder="placeTitle"
      :trigger-on-focus="false"
      @select="handleCompanyId"
    >
      <template slot-scope="{ item }">
        <div class="name" :title="item.value">{{ item.value }}</div>
      </template>
    </el-autocomplete>
    <el-button
      @click="search"
      class="btn"
      :type="(PJSource==12||PJSource==16)?'primary':'danger'"
      icon="el-icon-search"
    ></el-button>
  </div>
</template>

<script>
export default {
  name: "searchCompany",
  props: {
    value: {
      type: String,
    },
    placeTitle: {
      type: String,
      default: "请输入企业名称",
    },
    index: {
      type: Number,
    },
    genreType: {
      type: Number | String,
      default: "",
    },
  },
  data() {
    return {
      name: this.value,
      zhReg: /^[\u4e00-\u9fa5]+$/i,
    };
  },
  watch: {
    name(nv) {
      this.$emit("input", nv);
      if (nv === "") {
        this.$emit("pitch", "");
      }
    },
    value(nv) {
      this.name = nv;
    },
  },
  mounted() {
    console.log('PJSourcePJSource',this.PJSource);
  },
  methods: {
    async querySearchCompanyName(queryString, cb) {
      // if (queryString.length < 3) {
      //   cb([]);
      //   return;
      // }
      let params = {};
      params.company_name = queryString;
      // params.certificate = 20;
      params.source = this.PJSource;
      // params.genre_ids = this.genreType;
      params.usage=2
      params.is_vip=1
      let res = await this.$store.dispatch(
        "baseStore/company_associateSearch",
        params
      );
      let values;

      if (this.zhReg.test(queryString)) {
        values = res.data.map((item) => {
          item.value = item.name_zh;
          return item;
        });
      } else {
        // 输入是英文或者是数字
        values = res.data.map((item) => {
          if (item.name_en) {
            // 返回数据中，英文有值
            item.value = item.name_en;
          } else {
            item.value = item.name_zh;
          }
          return item;
        });
      }
      cb(values);
    },
    handleCompanyId(item) {
      if (this.index !== undefined) {
        this.$emit("pitch", {
          item: item,
          index: this.index,
        });
      } else {
        this.$emit("pitch", item);
      }
    },
    search() {
      this.$emit("search");
    },
  },
};
</script>

<style scoped lang="less">
.search {
  .inline-input {
    width: 300px;
    position: relative;
    right: -5px;
  }
  .btn {
    position: relative;
    z-index: 5;
    border-radius: 0 4px 4px 0;
  }
}
.name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
