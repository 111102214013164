<template>
  <div class="siffaPageBottom">
    <div class="content_area ">
      <div class="contact_follow clearfix">
        <div class="contact">
          <h2>联系我们</h2>
          <ul>
            <li v-for="(item, i) in contact_arr" :key="i" class="item">
              {{ item.label }}{{ item.content }}
            </li>
          </ul>
        </div>
        <div class="follow" v-if="QRcode_arr.length">
          <h2>关注我们</h2>
          <ul>
            <li v-for="(item, i) in QRcode_arr" :key="i">
              <el-image :src="item.url" fit="contain"></el-image>
              <div class="tc" v-if="i !== 0">{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bottom tc">
      <div>
        <img
          class="gonganImg"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/gongan.png"
          alt
        />
        <a href="http://beian.miit.gov.cn/"> 闽ICP备09012257号-1 </a>
      </div>
      <div class="">
        Copyright@2021 厦门市国际货运代理协会 版权所有
        <a href="https://ailaworld.com.cn/">AiLaworld.com</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Links_arr: [
        {
          lable: "上海海关",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href.png",
          href: "http://shanghai.customs.gov.cn/",
        },
        {
          lable: "科越信息",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href1.png",
          href: "http://www.etongguan.com/",
        },
        {
          lable: "上海出入境检验检疫局",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href2.png",
          href: "http://www.shciq.gov.cn/",
        },
        {
          lable: "中国上海",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href3.png",
          href: "http://www.shanghai.gov.cn/",
        },
        {
          lable: "上海市商务委员会",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href4.png",
          href: "https://sww.sh.gov.cn/",
        },
        {
          lable: "海关总署",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href5.png",
          href: "http://www.customs.gov.cn/",
        },
        {
          lable: "AiLa",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/href/href6.png",
          href: "https://www.ailaworld.com.cn",
        },
      ],
      contact_arr: [
        {
          label: "协会地址：",
          content: "厦门市湖滨北路72号中闽大厦10楼08单元",
        },
        {
          label: "培训部电话：",
          content: "0592-5068086",
        },
        // {
        //   label: "会员部电话：",
        //   content: "0592-5068437",
        // },
        {
          label: "邮箱：",
          content: "sqz1977@xiffa.com.cn 邵小姐",
        },
      ],
      tel: [
        {
          lable: "秘书处总机：",
          content: "021-65374744",
        },
        {
          lable: "入会联系：",
          content: "021-68892891",
        },
        {
          lable: "业务咨询：",
          content: "021-68892892",
        },
        {
          lable: "培训联系：",
          content: "021-68892894",
        },
        {
          lable: "赞助联系：",
          content: "021-68892893",
        },
        {
          lable: "跨境贸易分会联系：",
          content: "021-20953528",
        },
        {
          lable: "空运分会联系：",
          content: "021-58102609",
        },
      ],
      QRcode_arr: [
        // {
        //   name: "微信",
        //   url:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/vx.png",
        // },
        {
          name: "二维码",
          url:
            "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/xif/erweima.jpg",
        },
      ],
    };
  },
  methods: {
    link_click(row) {},
    toHref(item) {
      window.open(item.href);
    },
  },
};
</script>

<style scoped lang="less">
.siffaPageBottom {
  width: 100%;
  background: #232d35;
  .friendLink {
    padding: 15px 0px 10px;
    font-size: 16px;
    color: #fff;
  }
  .link_box {
    display: flex;
    li {
      cursor: pointer;
      margin-right: 24px;
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff;
      line-height: 22px;
    }
  }
  .concatTel {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    li {
      width: 250px;
      text-align: left;
    }
  }
  .contact_follow {
    padding-top: 42px;
    display: flex;
    h2 {
      font-size: 16px;
      font-family: PingFang-SC-Heavy, PingFang-SC;
      font-weight: 800;
      color: #dcdfe6;
      line-height: 22px;
    }
    .contact {
      padding-bottom: 26px;
      width: 1070px;
      ul {
        padding-top: 26px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #dcdfe6;
          line-height: 30px;
          &:first-child {
            margin-bottom: 20px;
          }
        }
      }
    }
    .follow {
      min-width: 200px;
      color: #fff;
      ul {
        display: flex;
        padding-top: 20px;
        li {
          margin-right: 20px;
          width: 130px;
          height: 130px;
          img {
            width: 130px;
            height: 130px;
          }
        }
      }
    }
  }
  .bottom {
    height: 48px;
    background: #192026;
    color: #999999;
    font-size: 14px;
    padding-top: 3px;
    div{
      color: #999999;
    }
    a {
      color: #999999;
    }
  }
}
</style>
